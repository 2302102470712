import {
  BookingsQueryParams,
  getUrlQueryParamValue,
  Referrals,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { IWixAPI } from '@wix/yoshi-flow-editor';

export const extractQueryParamValues = ({
  wixCodeApi,
  queryParam,
}: {
  queryParam: BookingsQueryParams;
  wixCodeApi: IWixAPI;
}): string[] => {
  const queryParamValues = getUrlQueryParamValue(wixCodeApi, queryParam);

  if (queryParamValues) {
    const queryParamValuesArray = queryParamValues.split(',');
    return queryParamValuesArray.map((value) => value.trim());
  }

  return [];
};

export const isBackFromFormWithCart = (wixCodeApi: IWixAPI) => {
  const referral = getUrlQueryParamValue(
    wixCodeApi,
    BookingsQueryParams.REFERRAL,
  );

  return referral === Referrals.BOOKINGS_FORM_WIDGET_WITH_CART;
};
